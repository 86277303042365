<template>
  <el-card style="margin-top: 15px">
    <el-row class="title_row">
      <el-col>
        <span>联系人信息</span>
        <el-button
          v-if="editable"
          type="primary"
          size="mini"
          icon="el-icon-circle-plus-outline"
          style="margin-left: 10px"
          @click="addContactDialogVisible = true"
          >新增</el-button
        >
      </el-col>
    </el-row>
    <el-table
      header-cell-class-name="tableHeaderStyle"
      :data="data"
      border
      stripe
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="手机" prop="phone"></el-table-column>
      <el-table-column label="职务" prop="work"></el-table-column>
      <el-table-column
        label="类型"
        prop="gameType"
        :formatter="gameTypeNameMatter"
      ></el-table-column>
      <el-table-column label="微信" prop="wechat"></el-table-column>
      <el-table-column label="邮箱" prop="email"></el-table-column>
      <el-table-column label="操作" v-if="editable">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click="editContactDialog(scope.$index)"
            >编辑</el-button
          >
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click="remove(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增联系人信息dialog -->
    <el-dialog
      title="新增联系人"
      :visible.sync="addContactDialogVisible"
      width="30%"
      @close="addContactDialogClose"
    >
      <el-form
        :model="addContactForm"
        ref="addContactFormRef"
        :rules="addContactFormRules"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.phone"
          ></el-input> </el-form-item
        ><el-form-item label="职务" prop="work">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.work"
          ></el-input> </el-form-item
        ><el-form-item label="类型" prop="gameTypeGroup">
          <el-select
            placeholder="请选择类型"
            style="width: 100%"
            ref="addContactGameTypeSelectRef"
            v-model="addContactForm.gameTypeGroup"
            @change="$forceUpdate()"
            multiple
          >
            <el-option
              v-for="item in dict_contact_type"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select> </el-form-item
        ><el-form-item label="微信" prop="wechat">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.wechat"
          ></el-input> </el-form-item
        ><el-form-item label="邮箱" prop="email">
          <el-input
            placeholder="请输入"
            v-model="addContactForm.email"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑联系人信息dialog -->
    <el-dialog
      title="编辑联系人"
      :visible.sync="editContactDialogVisible"
      width="30%"
      @close="editContactDialogClose"
    >
      <el-form
        :model="editContactForm"
        ref="editContactFormRef"
        :rules="editContactFormRules"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.phone"
          ></el-input> </el-form-item
        ><el-form-item label="职务" prop="work">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.work"
          ></el-input> </el-form-item
        ><el-form-item label="类型" prop="gameTypeGroup">
          <el-select
            placeholder="请选择类型"
            style="width: 100%"
            ref="editContactGameTypeSelectRef"
            v-model="editContactForm.gameTypeGroup"
            @change="$forceUpdate()"
            multiple
          >
            <el-option
              v-for="item in dict_contact_type"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select> </el-form-item
        ><el-form-item label="微信" prop="wechat">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.wechat"
          ></el-input> </el-form-item
        ><el-form-item label="邮箱" prop="email">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.email"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: 'contactList',
  props: {
    data: {
      type: Array,
      require: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dict_contact_type: this.$userInfo.getDataList(this, 't_contact_type').then(value => { this.dict_contact_type = value }),
      addContactDialogVisible: false,
      addContactForm: {
        name: '',
        phone: '',
        work: '',
        gameType: '',
        gameTypeGroup: [],
        wechat: '',
        email: ''
      },
      addContactFormRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        work: [{ required: true, message: '请输入职务', trigger: 'blur' }],
        gameTypeGroup: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      editContactDialogVisible: false,
      editContactForm: {},
      editContactFormRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }],
        work: [{ required: true, message: '请输入职务', trigger: 'blur' }],
        gameTypeGroup: [{ required: true, message: '请选择类型', trigger: 'change' }]
      },
      willEditContactIndex: ''
    }
  },
  methods: {
    add () {
      this.$refs.addContactFormRef.validate(valid => {
        if (!valid) return
        this.addContactForm.gameType = this.addContactForm.gameTypeGroup.join(',')
        this.$emit('add', this.addContactForm)
        this.addContactDialogVisible = false
      })
    },
    edit () {
      this.$refs.editContactFormRef.validate(valid => {
        if (!valid) return
        this.editContactForm.gameType = this.editContactForm.gameTypeGroup.join(',')
        this.$emit('edit', this.willEditContactIndex, this.editContactForm)
        this.editContactDialogVisible = false
      })
    },
    remove (index) {
      this.$emit('remove', index)
    },
    editContactDialog (index) {
      this.willEditContactIndex = index
      var editContactData = this.data[index]
      console.log('editContactData', editContactData)
      editContactData.gameTypeGroup = editContactData.gameType.split(',')
      this.editContactForm = { ...editContactData }
      this.editContactDialogVisible = true
    },
    addContactDialogClose () {
      this.$refs.addContactFormRef.resetFields()
    },
    editContactDialogClose () {
      this.$refs.editContactFormRef.resetFields()
      this.editContactForm = {}
    },
    gameTypeNameMatter (value) {
      if (value.gameType) {
        var ctopicList = value.gameType.split(',')
        var temp = ''
        for (const key in this.dict_contact_type) {
          if (Object.hasOwnProperty.call(this.dict_contact_type, key)) {
            const element = this.dict_contact_type[key]
            for (let index = 0; index < ctopicList.length; index++) {
              const childElement = ctopicList[index]
              if (element.dictValue === childElement + '') {
                temp = temp + (temp === '' ? '' : ',') + element.dictLabel
              }
            }
          }
        }
        return temp
      }
    }
  }
}
</script>

<style>
</style>
