import { render, staticRenderFns } from "./association_detail_info.vue?vue&type=template&id=1be7c736&scoped=true&"
import script from "./association_detail_info.vue?vue&type=script&lang=js&"
export * from "./association_detail_info.vue?vue&type=script&lang=js&"
import style0 from "./association_detail_info.vue?vue&type=style&index=0&id=1be7c736&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1be7c736",
  null
  
)

export default component.exports