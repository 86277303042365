<template>
  <div>
    <el-row class="title_row" align="middle" type="flex">
      <el-col :span="15">
        <span class="title-class">授课教师</span>
      </el-col>
      <el-col :span="9">
        <div class="div_algin_right">
          <el-button type="primary" @click="addSchoolTeacherInfoClicked()"
            >新增教师+</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-table
      header-cell-class-name="tableHeaderStyle"
      :data="dataList"
      border
      stripe
    >
      <el-table-column label="序号" type="index"></el-table-column>
      <el-table-column label="教师名称" prop="userName"></el-table-column>
      <el-table-column label="教师联系方式" prop="phone"></el-table-column>
      <el-table-column
        label="授课信息"
        :show-overflow-tooltip="true"
        prop="productNameStr"
      ></el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-switch
            :active-value="0"
            :inactive-value="1"
            v-model="scope.row.isDelete"
            @change="accountStateChange(scope.row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="editTeacherMessage(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="mini" @click="deleteDialog(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增、编辑授课老师信息dialog -->
    <el-dialog
      title="授课老师信息"
      :visible.sync="editContactDialogVisible"
      width="30%"
      @close="editContactDialogClose"
    >
      <el-form
        :model="editContactForm"
        ref="editContactFormRef"
        :rules="editContactFormRules"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="userName">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.userName"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input
            placeholder="请输入"
            v-model="editContactForm.phone"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editContactDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSaveClicked">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSchoolTeacherList,
  addSchoolTeacherOrganization,
  updateSchoolTeacher,
  deleteSchoolTeacher,
  disableSchoolTeacher
} from '@/http/api'
export default {
  data () {
    return {
      changeType: '',
      editContactForm: {},
      editContactDialogVisible: false,
      editInfoForm: JSON.parse(this.$route.query.editInfoForm),
      dataList: [
      ],
      editContactFormRules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入电话', trigger: 'blur' }]
      }
    }
  },
  created () {
  },
  methods: {
    getSchoolTeacherList () {
      console.log('被调用了')
      if (!this.editInfoForm.id) {
        return this.$message.warning('请先创建学校！')
      }
      getSchoolTeacherList({ schoolId: this.editInfoForm.id, roleId: this.$chnEngStatusCode.ADMINISTRATOR_ASSOCIATION }).then((res) => {
        console.log('getSchoolTeacherList', res)
        this.dataList = res.data
        if (this.openTurnType === 'teacher') {
          this.getCourseList()
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    accountStateChange (data) {
      disableSchoolTeacher({ id: data.id, isDelete: data.isDelete ? 1 : 0, schoolId: this.editInfoForm.id }).then((res) => {
        this.$message.success('状态修改成功')
        this.getSchoolTeacherList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 点击添加教师
    addSchoolTeacherInfoClicked () {
      this.editContactForm = {}
      this.editContactForm.schoolId = this.editInfoForm.id
      this.editContactForm.roleId = this.$chnEngStatusCode.ADMINISTRATOR_ASSOCIATION
      this.editContactDialogVisible = true
      this.changeType = 'add'
    },
    // 编辑教师信息
    editTeacherMessage (item) {
      this.editContactForm = item
      this.editContactDialogVisible = true
      this.changeType = 'change'
    },
    // 保存教师信息
    editSaveClicked () {
      if (this.changeType === 'add') {
        addSchoolTeacherOrganization(this.editContactForm).then((res) => {
          console.log('addSchoolTeacherOrganization', res)
          this.$message.success('保存成功')
          this.editContactDialogVisible = false
          this.getSchoolTeacherList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        updateSchoolTeacher(this.editContactForm).then((res) => {
          this.$message.success('保存成功')
          this.editContactDialogVisible = false
          this.getSchoolTeacherList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    // 编辑教师信息弹窗关闭事件
    editContactDialogClose () {
      this.$refs.editContactFormRef.resetFields()
    },
    // 删除教师信息
    deleteDialog (item) {
      this.$confirm('是否删除该信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确认删除
        this.confirmDelectInfo(item)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 确认删除信息
    confirmDelectInfo (item) {
      deleteSchoolTeacher({ id: item.id, isDelete: 1, schoolId: this.editInfoForm.id }).then((res) => {
        this.$message.success('删除成功')
        this.getSchoolTeacherList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.div_algin_right {
  text-align: right;
}
</style>
